/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
==========================================================================================*/

const menuItem = [
  {
    url: "/franchise/dashboard",
    name: "Dashboard",
    icon: "MonitorIcon",
    slug: "dashboard",
    i18n: "Dashboard",
  },
  {
    url: "/franchise/clinics",
    name: "FranchiseClinics",
    slug: "franchise-clinics-management",
    i18n: "Clinics Management",
    icon: "PackageIcon",
  },
  {
    url: "/franchise/doctors",
    name: "FranchiseDoctors",
    slug: "franchise-doctors-management",
    i18n: "Doctors Management",
    icon: "HeadphonesIcon",
  },
  {
    url: "/franchise/nurses",
    name: "Nurses Management",
    slug: "pages-register-nurse",
    i18n: "Nurses Management",
    icon: "UserIcon",
  },
  {
    url: "/franchise/patients",
    name: "Patient Management",
    slug: "pages-register-nurse",
    i18n: "Patient Management",
    icon: "UsersIcon",
  },
  {
    url: "/franchise/invoices",
    name: "Invoice Management",
    slug: "invoice",
    i18n: "Invoice Management",
    icon: "CreditCardIcon",
  },
  // {
  //   url: '/franchise/assign-clinic',
  //   name: "Assign Clinic",
  //   slug: "assign-clinic",
  //   i18n: "Assign Clinic",
  //   icon: "PlusIcon",
  // },
  {
    url: "/franchise/pair-device",
    name: "Pair Device",
    slug: "pair-device",
    i18n: "Pair Devices",
    icon: "SmartphoneIcon",
  },
  {
    url: "/franchise/events",
    name: "Events",
    icon: "CalendarIcon",
    slug: "events",
    i18n: "Events",
  },
  {
    url: "/franchise/resources",
    name: "Resources",
    icon: "FolderIcon",
    slug: "resources",
    i18n: "Resources",
  },
  {
    header: "Settings",
    icon: "SettingsIcon",
    i18n: "Settings",
    items: [
      {
        url: "/franchise/settings/products-list",
        name: "Franchise Products List",
        slug: "franchise-products-list",
        icon: "CheckSquareIcon",
        i18n: "Products List",
      },
      {
        url: "/franchise/settings/treatment-areas",
        name: "Franchise Treatment Areas",
        slug: "franchise-treatment-areas",
        icon: "ShuffleIcon",
        i18n: "Treatment Areas",
      },
    ],
  },
];
export default menuItem;
